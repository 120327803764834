
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

@import "node_modules/@swimlane/ngx-datatable/index.css";
@import "node_modules/@swimlane/ngx-datatable/themes/dark.css";
@import "node_modules/@swimlane/ngx-datatable/themes/bootstrap.css";
@import "node_modules/@swimlane/ngx-datatable/themes/material.css";
@import "node_modules/@swimlane/ngx-datatable/assets/icons.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

html{
    height:100%;
}
.mat-body, .mat-body-2,
.mat-typography,
body {
  font-family: Roboto,sans-serif;
  font-size: 12px;
  line-height: 20px;
  height: 100%;
  overflow: hidden;
}

h3 {
  font-size: 22px;
  font-weight: normal;
}

.mat-mdc-button-disabled {
  cursor: not-allowed;
}

.mdc-button.mat-primary {
  background-color: #9f3118 !important;
  color: white;
}

.mat-mdc-raised-button[disabled], .mat-mdc-raised-button.mat-mdc-button-disabled {
  background-color: var(--mdc-protected-button-disabled-container-color) !important;
}

.no-margin {
  margin: 0 !important;
}

.general-icon {
  display: inline-block;
  margin-right: 5px;
}
a {
  color: #9f3118;
}
  a:hover {
    color: #9f3118;
  }

.form-group label {
  font-weight: 400;
  margin-bottom: 0;
}

body.dark-theme .add-modal table.table {
  color: #000000;
}

body.dark-theme .add-modal table,
body.dark-theme .add-modal table tr td,
body.dark-theme .add-modal .table-striped > tbody > tr:nth-of-type(odd) > * {
  color: #000000;
}

.popup-close-btn {
  font-size: 18px;
}

body.dark-theme .add-modal .ng-select.ng-select-single .ng-select-container {
  height: 32px;
}

body.dark-theme .add-modal .ng-select .ng-select-container {
  min-height: 32px;
}


.accordion-button:not(.collapsed) {
  color: #000;
  background-color: #f7dcc5 !important;
  box-shadow: none;
}

.modal-content {
  color: #000;
}

.modal-footer {
  margin-top: 45px;
  padding: 10px 10px;
}

.text-bold {
  font-weight: bold;
}

.text-right {
  text-align: right;
}

.mat-mdc-tooltip {
  font-size: 14px;
}

.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  font-size: 12px;
}

.ngx-datatable.material {
  background: transparent;
}

.card {
  background-color: transparent;
  border: 1px solid #dddddd;
}

.mat-mdc-table {
  background: transparent;
  color: #000000;
}

.mat-mdc-cell, .mat-mdc-footer-cell {
  color: #000000;
}


ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

table.mat-mdc-table {
  width: 100%;
}

.build-version .ml-10 {
  display: inline-block;
  margin-left: 10px;
}

.popup-close-btn {
  float: right;
  color: #dc3545;
  margin-right: 5px;
}

.mat-mdc-dialog-content .add-parent-section .ng-select-container {
  background: #ffffff;
}


.form-check-input:checked {
  background-color: #9f3118;
  border-color: #9f3118;
}


/*TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
mat-dialog-container.mat-mdc-dialog-container {
  padding-top: 12px;
}

.popup-header {
  border-bottom: 1px solid #cccccc;
  margin: 0 -25px 15px;
  padding: 0 25px 12px;
}

.mat-mdc-dialog-actions button {
  margin-left: 10px;
}

.mat-mdc-dialog-actions {
  border-top: 1px solid #cccccc;
  margin: 15px -25px 0px;
  padding: 0 25px !important;
}

.dropdown-item:focus, .dropdown-item:hover {
  background-color: #f8f8f8;
}

.required-str {
  color: red;
}

.ngx-datatable.material .datatable-header,
.ngx-datatable.material .datatable-header .datatable-header-cell,
mat-header-row, .mat-mdc-header-cell,
table thead tr th {
  background-color: #9f3118 !important;
  color: #ffffff;
  font-size: 14px;
}
table thead tr th, table thead {
  color: #ffffff;
}
table.table {
  --bs-table-bg: inherit;
}

.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  color: #000000;
}

.ngx-datatable.material .datatable-header .datatable-header-cell {
  padding: 0.9rem 0.6rem;
}

.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  padding: 0.5rem 0.6rem;
}

.ngx-datatable .datatable-header .datatable-header-cell .datatable-header-cell-template-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 70px;
}

.mat-sort-header-arrow, [dir=rtl] .mat-sort-header-position-before .mat-sort-header-arrow {
  color: #ffffff;
}

mat-row, mat-footer-row,
mat-header-row {
  min-height: 40px !important;
}


#logoMain {
  width: 60px;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
}

section.maximize #logoMain {
  width: 151px;
  height: 31px;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
}





.logo {
  width: 70px;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  padding: 14px 5px 2px 12px;
  background: #e7e7e7;
  min-height: 59px;
  white-space: nowrap;
  border-right: 1px solid #d5d5d5;
}

section.maximize .logo {
  width: 230px;
  padding: 14px 5px 2px 12px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
}

.max-logo {
  display: inline-block;
  margin-left: 15px;
  vertical-align: middle;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 16px;
}

  .max-logo b {
    display: block;
    font-weight: normal;
  }

section.minimize .max-logo {
  display: none;
}

/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-disabled {
  cursor: pointer;
}


.mat-mdc-dialog-container {
  overflow: hidden !important;
}

.loginFormSelect .ng-value-container .ng-value {
  color: #000 !important;
}



.mat-mdc-dialog-title {
  margin: 0px !important
}

mat-cell {
  cursor: pointer !important;
}

.selectFilter mat-checkbox {
  margin-right: 20px;
}

.ngx-datatable .datatable-body .datatable-row-wrapper {
  border-bottom: 1px solid #dddddd;
  background: #ffffff;
}

.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover, .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background-color: #ffffff;
}

.ngx-datatable .datatable-body .datatable-row-wrapper:nth-child(2n+1) {
  background-color: #e8e9e9;
}

.dashboard-grid .ngx-datatable .datatable-body {
  max-height: calc(100vh - 265px);
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.dashboard-grid .ngx-datatable {
  min-height: calc(100vh - 265px);
  max-height: calc(100vh - 265px);
  margin-bottom: 0px;
}

.dashboard-grid.programe-grid {
  height: 100%;
  max-height: 100%;
}

  .dashboard-grid.programe-grid .ngx-datatable .datatable-body {
    max-height: 520px;
  }

  .dashboard-grid.programe-grid .ngx-datatable {
    max-height: 640px;
  }

.dashboard-grid.programe-grid {
  max-height: 640px;
}


section.minimize .inner-sidebar .profile-name {
  font-weight: 500;
  visibility: hidden;
  opacity: 0;
  width: 0px;
  font-size: 0px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
}
section.minimize .inner-sidebar .item-icon.user-big {
  font-size: 25px !important;
  padding: 10px !important;
}
section.minimize .user-section {
  padding: 15px 10px !important;
}


section.minimize .inner-sidebar ul li a .hide-mini {
  visibility: hidden;
  opacity: 0;
  width: 0px;
  font-size: 0px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
}


.sub-heading {
  font-size: 18px;
  color: #9f3118;
  border-bottom: 1px dashed #9f3118;
  margin-bottom: 15px;
  padding-bottom: 5px;
}

.accordion-button:not(.collapsed) {
    color:#000000;
}




@media(max-width:1366px) {
  .dashboard-grid.programe-grid .ngx-datatable {
    max-height: 580px;
  }

  .dashboard-grid.programe-grid {
    max-height: 580px;
  }
}


.add-modal form input.form-control::placeholder {
  color: #cccccc;
}


.add-modal form input.form-control {
  color: #000000;
}

/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.dark-theme .cdk-overlay-pane .mat-select-panel-wrap .form-control {
  color: #000000;
}

.add-modal form textarea.form-control {
  color: #000000;
}

.add-modal form .form-group .ng-select .ng-select-container {
  color: #000000;
  background: #ffffff;
}


.mat-mdc-dialog-content .ng-select .ng-select-container,
.mat-dialog-content .ng-select .ng-select-container .ng-value-container .ng-input > input {
  color: #000000;
}

select.form-control option {
  color: #000000;
}

.ng-select.ng-select-single.k-required {
  border-radius: 4px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option:hover {
  background-color: #9f3118;
  color: #fff;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  color: #fff;
  background-color: #9f3118;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option{
    overflow:visible!important;
}


.mat-datepicker-content .mat-calendar {
  min-width: 240px !important;
  max-width: 250px !important;
  min-height: 250px !important;
  max-height: 300px !important;
}

.mat-calendar-body-selected {
  background-color: #9f3118;
}

.mat-calendar-table-header th {
  padding: 0px !important;
}





mat-row:nth-child(2n+1) {
  background-color: #eeeeee;
}

mat-row:hover {
  background: #eeeeee;
}

mat-row, mat-header-row, mat-footer-row, th.mat-mdc-header-cell, td.mat-mdc-cell, td.mat-mdc-footer-cell {
  border-bottom-color: #cfcfcf;
}

.mat-mdc-paginator {
  background: transparent;
}

/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-mdc-paginator, .mat-mdc-paginator-page-size .mat-select-trigger {
  color: #ffffff;
}

/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-select-value,
.mat-select-arrow {
  color: #000000;
}

.mat-mdc-select-panel .mat-mdc-option.mat-selected:not(.mat-mdc-option-multiple) {
  background-color: #9f3118;
  color: #fff;
}

.mat-mdc-paginator-page-size-label {
  color: #9f3118;
  margin: 0 4px;
  font-weight: 500;
}

.btn.btn-primary.btn-sm.add-btn {
  background-color: #9f3118 !important;
  color: #ffffff !important;
  border-color: #9f3118 !important;
}

.LocationPage .ngx-datatable {
  height: auto;
  min-height: calc(100vh - 430px);
  max-height: calc(100vh - 430px);
  margin-bottom: 5px;
}

  .LocationPage .ngx-datatable .datatable-body {
    max-height: calc(100vh - 520px);
    overflow-y: auto;
    overflow-x: auto;
  }

.LocationPage.risk-evaluation .ngx-datatable {
  height: auto;
  min-height: 300px;
  max-height: 300px;
}
  .LocationPage.risk-evaluation .ngx-datatable .datatable-body {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: auto;
    padding-bottom: 95px;
  }


.BuildingPage .ngx-datatable {
  height: auto;
  min-height: 300px;
  /*min-height: calc(100vh - 430px);
  max-height: calc(100vh - 430px);*/
  max-height: 197px;
  margin-bottom: 5px;
}

.BuildingPage .ngx-datatable .datatable-body {
  max-height: 197px;
  /*max-height: calc(100vh - 520px);*/
  overflow-y: auto;
  overflow-x: auto;
}

.mat-mdc-paginator-range-label {
  margin: 0 32px 0 24px;
  color: #9f3118;
  font-weight: 500;
}

/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-button-wrapper {
  color: #9f3118;
}

/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #dd7b65;
}
/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.accordion-item .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #dd8774;
}


/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-checkmark-path {
  stroke: #9f3118 !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-frame {
  border-color: #df7d67;
}

/*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-radio-outer-circle {
  border-color: #df7d67;
}

.mat-mdc-radio-button.mat-primary .mat-radio-inner-circle, .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #dd7b65;
}

/*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-mdc-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #dd7b65;
}

.ngx-datatable.material .datatable-footer .datatable-pager li.active a {
  background-color: #9f3118;
  color: #ffffff;
}

.ngx-datatable.material .datatable-footer .datatable-pager a {
  color: #000000;
}

.ngx-datatable.material .datatable-footer .datatable-pager li.disabled a {
  color: #777777 !important;
}


.ngx-datatable.material .datatable-footer {
  color: #9f3118;
  font-weight: 500;
  position: fixed;
  bottom: 0px;
  z-index: 9999;
  background-color: #ffffff;
}

.ngx-datatable {
  height: calc(100vh - 260px);
  margin-bottom: 35px;
  width: 100% !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.19) !important;
}
  .ngx-datatable.material .datatable-header,
  .ngx-datatable.scroll-horz .datatable-body {
    width: 100% !important;
  }

.report-section-grid .ngx-datatable {
  height: calc(100vh - 185px);
}

.user-account .dropdown-menu {
  background: #f7dcc5;
  color: #ffffff;
  border: 1px solid #dddddd;
}

  .user-account .dropdown-menu a {
    color: #000000;
  }

.user-account .dropdown-item:focus, .user-account .dropdown-item:hover {
  background-color: transparent;
}
.ngx-datatable .btnBox button {
  margin-top: -3px;
}
.btn-primary {
  background: #9f3118;
  border-color: #9f3118;
  color: #fff;
  box-shadow: none;
}

  .btn-primary:hover,
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #9f3118;
    border-color: #9f3118;
  }

  .btn-primary:active, .btn-primary.active,
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #9f3118;
    border-color: #9f3118;
    box-shadow: none;
  }

    .btn-primary:active:focus,
    .btn-danger:focus, .btn-danger:active,
    .btn-danger {
      box-shadow: none;
    }

  .btn-primary.disabled, .btn-primary:disabled {
    background-color: #8b4b3d;
    border-color: #8b4b3d;
  }

select.form-control {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: url(./assets/images/down-arrow.png) no-repeat 98.5% center / 10px;
  padding-right: 40px;
  background-color: #ffffff;
  line-height: 16px;
}

select::-ms-expand {
  display: none;
}


.custom-control label {
  cursor: pointer;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #1579b2;
  background-color: #1579b2;
}


.main-heading {
  font-size: 22px;
  margin-bottom: 10px;
}


.no-pad {
  padding: 0px !important;
}

.form-control {
  padding: .275rem .55rem;
  font-size: 12px;
  height: calc(1.5em + 0.75rem + 2px);
}

  .form-control:focus {
    box-shadow: none;
  }

select.form-control:not([size]):not([multiple]) {
  height: calc(1.3em + 0.75rem + 2px);
}

h3.form-title {
  font-size: 24px;
  margin-bottom: 20px;
}

/*TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
mat-dialog-content select.form-control:focus,
mat-dialog-content select.form-control {
  background-color: #ffffff;
  color: #000000;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option,
.ng-select .ng-select-container .ng-value-container {
  font-size: 12px;
}




/* scrollbar css added here*/
/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 15px #ffffff;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #222222;
  border-radius: 10px;
}

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #333333;
  }



.required-str {
  color: #ff0000;
  display: inline-block;
  margin-left: 2px;
}

.required {
  border-color: #ee9191;
}

.toast-green {
  background: #aae9a9;
  color: #155724;
}
.toast-green .toast-header {
    background: #aae9a9;
  }

p.footer-text {
  margin-bottom: 0px;
  padding-left: 65px;
}

.maximize p.footer-text {
  padding-left: 240px;
}


input[type="radio"]:hover {
  cursor: pointer;
}


.accordion-button::after {
  background-image: none;
}




.accordion-button:not(.collapsed)::after {
  background-image: none !important;
}

.accordion-item {
  background-color: transparent;
  border: 1px solid #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.19);
  border-top: none;
}



  /*Loader Css start here*/

.la-ball-scale-multiple.la-3x, .la-ball-scale-multiple.la-3x > div {
  display: none !important;
}

@keyframes loader {
  0%, 10%, 100% {
    width: 20px;
    height: 20px;
  }

  65% {
    width: 80px;
    height: 80px;
  }
}

@keyframes loaderBlock {
  0%, 30% {
    transform: rotate(0);
  }

  55% {
    background-color: #9f3118;
  }

  100% {
    transform: rotate(90deg);
  }
}

@keyframes loaderBlockInverse {
  0%, 20% {
    transform: rotate(0);
  }

  55% {
    background-color: #9f3118;
  }

  100% {
    transform: rotate(-90deg);
  }
}




.filter-search-conatiner {
  padding: 15px;
}


/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #9f3118;
}


.ngx-datatable.material.cell-selection .datatable-body-cell.active:focus {
  background-color: #9f3118;
  color: #fff;
}

/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-disabled {
  cursor: pointer !important;
}


.search-application-tbl {
  max-height: calc(100vh - 270px);
  overflow-y: auto;
}

.data-maintainace .add-user.text-right {
  margin-right: 25px;
}

.mat-mdc-dialog-content,
.mat-mdc-dialog-container {
  overflow-x: hidden !important;
}

input[type="radio"] {
  font-size: 15px;
  border: 1px solid #000;
}


.table > :not(:first-child) {
  border-top: 0px solid currentColor;
}



.form-check-label {
  cursor: pointer;
  margin-top: 4px;
}

input[type="checkbox"] {
  width: 17px;
  height: 17px;
  cursor: pointer;
}
.form-check-input:focus {
  border-color: #8b4b3d;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(139, 75, 61, 0.24);
  -webkit-box-shadow: 0 0 0 0.25rem rgba(139, 75, 61, 0.24);
  -moz-box-shadow: 0 0 0 0.25rem rgba(139, 75, 61, 0.24);
}
.datePicker input.form-control {
  box-sizing: border-box;
  padding: 0 8px;
}

.datePicker {
  position: relative;
}

.datePickerToggle {
  position: absolute;
  right: 0px;
  top: -4px;
}

.inputBorder {
  border: 1px solid #ced4da;
}


.accordion-item:first-of-type .accordion-button {
  border-radius: 15px;
}

.accordion-item {
  margin-bottom: 10px;
  border-radius: 15px 15px 15px 15px;
  background: transparent;
}
  .accordion-item:last-of-type {
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
  }

.accordion-button:not(.collapsed) {
  color: #fff;
  background-color: #384f80;
  box-shadow: none;
}

.accordion-button:focus {
  z-index: 3;
  border-color: #e8e8e8;
  outline: 0;
  box-shadow: none;
}


.accordion-button {
  background-color: #f7dcc5;
  border-radius: 15px;
  padding: 0.75rem 1.25rem;
  color: #fff;
}

.accordion-item:first-of-type {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}


.accordion-button strong {
  color: #9f3118;
  font-weight: 500;
  font-size: 21px;
}

mat-header-row.mat-mdc-header-row.cdk-header-row {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 2;
}



/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: #fff;
}

.modal-body {
  padding: 20px 20px !important;
}
.buildingChar .modal-body {
  padding: 5px 10px 10px !important;
}

.modal {
  text-align: center;
  padding: 0 !important;
  z-index: 999;
}

  .modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
  }

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  min-width: 700px;
  margin: 1.75rem auto;
}


.modal-header button {
  background-color: #fff;
  border: none;
  color: red;
  font-weight: 800;
  font-size: 20px;
}


.policySection {
  border: 1px solid #9f3118;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  margin-top: 20px;

}




.accordion-body {
  background-color: #ffffff;
  border-radius: 0px 0px 15px 15px;
}

.copyIcon, .deleteIcon, .editIcon {
  margin: 0px 20px 0px 0px;
}

.vesselForm {
  max-height: 70vh !important;
  overflow: auto;
}

.ngx-datatable.material .datatable-footer .datatable-pager a:hover {
  color: #9f3118;
}




/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.isActiveCheck.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-checkmark-path {
  stroke: #ffffff !important;
}


body.light-theme .login-bg .btn-primary:hover,
body.light-theme .login-bg .btn-primary:active,
body.light-theme .login-bg .btn-primary:focus,
body.light-theme .login-bg .btn-primary {
  color: #ffffff;
  background-color: #9f3118;
  border-color: #9f3118;
}

body.light-theme .login-bg .login-logo {
  color: #ffffff;
}


.mat-mdc-dialog-content input.form-control {
  color: #000;
}


/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.referalModal .mat-select-value .mat-select-arrow {
  color: #000;
}

/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.referalModal .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #1ebce7;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.referalModal .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0,0,0,.42);
}

/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.referalModal .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.50);
}

.updatedUserMargin input {
  margin-right: 10px;
}




/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.isActiveCheck.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background {
  background-color: #198c32;
}



/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.isInactiveCheck .mat-checkbox-frame {
  border-color: red !important;
}

.mat-mdc-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element, .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #df694f;
}
.mat-mdc-checkbox .mat-ripple-element {
  background-color: #f7dcc5;
}
.mat-datepicker-toggle-active {
  color: #cd6f5a;
}
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical), .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical), .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(205, 111, 90, 0.45);
}
.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(159, 49, 24,0);
}
/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-button-focus-overlay {
  background: #cd6f5a;
}
.ng-select.ng-select-focused:not(.ng-select-open) > .ng-select-container {
  border-color: #d7bcb6;
}

.dark-theme .mat-mdc-dialog-content .mat-select-value, .dark-theme .mat-dialog-content .mat-select-arrow {
  color: #000000;
}

/* Change Autocomplete styles in Chrome*/
/*input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #ffffff;
  -webkit-text-fill-color: #ffffff;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}*/

.loginFormSelect .ng-select .ng-select-container .ng-value-container .ng-input > input {
  color: #000 !important;
}

.modal-body .ng-select .ng-select-container .ng-value-container .ng-input > input {
  color: #000 !important;
}



.actionRight .ngx-datatable .datatable-row-center, .actionRight .ngx-datatable .datatable-row-group, .actionRight .ngx-datatable .datatable-row-left, .actionRight .ngx-datatable .datatable-row-right {
  position: relative;
  right: -38px;
}











/* Change Autocomplete styles in Chrome*/

.login-bg input:-webkit-autofill,
.login-bg input:-webkit-autofill:hover,
.login-bg input:-webkit-autofill:focus,
.login-bg textarea:-webkit-autofill,
.login-bg textarea:-webkit-autofill:hover,
.login-bg textarea:-webkit-autofill:focus,
.login-bg select:-webkit-autofill,
.login-bg select:-webkit-autofill:hover,
.login-bg select:-webkit-autofill:focus {
  border: 1px solid #cccccc;
  -webkit-text-fill-color: #000000;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

.signup-bg input:-webkit-autofill,
.signup-bg input:-webkit-autofill:hover,
.signup-bg input:-webkit-autofill:focus,
.signup-bg textarea:-webkit-autofill,
.signup-bg textarea:-webkit-autofill:hover,
.signup-bg textarea:-webkit-autofill:focus,
.signup-bg select:-webkit-autofill,
.signup-bg select:-webkit-autofill:hover,
.signup-bg select:-webkit-autofill:focus {
  border: 1px solid #cccccc;
  -webkit-text-fill-color: #000000;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}


.modal-body .ng-select .ng-select-container {
  color: #000 !important;
}

.add-modal input:-webkit-autofill,
.add-modal input:-webkit-autofill:hover,
.add-modal input:-webkit-autofill:focus,
.add-modal textarea:-webkit-autofill,
.add-modal textarea:-webkit-autofill:hover,
.add-modal textarea:-webkit-autofill:focus,
.add-modal select:-webkit-autofill,
.add-modal select:-webkit-autofill:hover,
.add-modal select:-webkit-autofill:focus {
  border: 1px solid #cccccc;
  -webkit-text-fill-color: #000000;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}



.modal-backdrop.show {
  opacity: 0;
}

.modal-backdrop {
  display: none;
}

.mat-mdc-cell,
.mat-mdc-header-cell,
.mat-mdc-footer-cell {
  padding: 8px;
}

  .mat-mdc-cell div,
  .mat-mdc-header-cell div,
  .mat-mdc-footer-cell div {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline;
    vertical-align: top;
  }

body.dark-theme table tr td.mat-calendar-body-label {
  color: #333333;
}

ng-multiselect-dropdown.k-required {
  border: none !important;
}

  ng-multiselect-dropdown.k-required .dropdown-btn {
    border: 2px solid red !important;
  }

body.light-theme .report-left {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
}

.multiselect-dropdown .dropdown-list {
  overflow: hidden;
}

  .multiselect-dropdown .dropdown-list ul {
    max-height: 125px !important;
  }

.ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 135px !important;
  overflow-x: auto !important;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.form-control:focus {
  border-color: #d7bcb6;
}

.accordion-button svg {
  color: #9f3118;
  position: absolute;
  right: 15px;
}


.k-required {
  border-color: #ff0000;
}
.ng-select.k-required .ng-select-container {
  border-color: #ff0000;
}


.submissionFormBtn button {
  margin:10px 10px 10px 0px
}

.submissionFormBtn {
  position: absolute;
  right: 0px;
}

.add-modal .ngx-datatable.material {
  height: 100%;
}
.add-modal .ngx-datatable.scroll-horz .datatable-body {
  padding-bottom: 38px;
}

.mat-mdc-icon-button{
  top: -3px !important;
}

.scrollClass .ngx-datatable.scroll-horz .datatable-body{
  max-height: 150px !important;
  overflow: auto !important;
}
.currency-gap{
    display:block;
}
.currency-gap::first-letter {
  margin-right: 2px;
}

.red-status {
  background: #dc3545;
  vertical-align: top;
  margin-left: 10px;
  color: #420006;
  display: inline-block;
  padding: 10px;
  border-radius: 0.25rem;
  margin-bottom: 10px;
  min-width: 31.5%;
}

.orange-status {
  display: inline-block;
  background: #fd7e14;
  vertical-align: top;
  margin-left: 10px;
  color: #2c2100;
  padding: 10px;
  border-radius: 0.25rem;
  margin-bottom: 10px;
  min-width: 31.5%;
}

/*override css start*/

.card-body {
  padding: 1rem 1rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
.modal-content {
  border: 1px solid rgba(0,0,0,.2);
}
.dropdown-item {
  padding: 0.25rem 1rem;
}
.toast-container {
  z-index: 999999;
}

.ng-dropdown-panel.title-control .ng-dropdown-panel-items .ng-option {
  padding: 0;
}

  .ng-dropdown-panel.title-control .ng-dropdown-panel-items .ng-option span.custom-potion {
    display: block;
    padding: 8px 10px;
  }


.table > :not(caption) > * > * {
  color: inherit;
}

/*override css end */

@media screen and (max-width: 1104px) {
  section .maximize nav.application-nav {
    margin-top: 55px;
  }

  .user-account {
    margin-bottom: 10px;
  }

  section.maximize .dashboard-container {
    margin-top: 65px;
  }
}

@media screen and (max-width: 944px) {
  .dashboard-container {
    margin-top: 65px;
  }
}


@media screen and (max-width: 944px) {
  .body-container-wrapper {
    margin-top: 55px;
  }
}
